.pooja-booking-container {
  background-color: #f9f9f9;
  padding: 20px;
}

.poojabooking {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  /* margin: 10px; */
}

.pooja-booking-heading {
  font-size: 2rem;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.booking-option {
  font-size: 1.2rem;
  margin: 10px 10px;
  padding: 15px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
  text-align: center;
}

.booking-option:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 480px) {
  .poojabooking {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
