.pricing-page {
  font-family: "Arial", sans-serif;
  padding: 20px;
  text-align: center;
}

.pricing-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
}

.pricing-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.pricing-category {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
}

.pricing-category:hover {
  transform: translateY(-5px);
}

.category-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #555;
  border-bottom: 2px solid #ffd700;
  display: inline-block;
  padding-bottom: 5px;
}

.category-items {
  list-style: none;
  padding: 0;
}

.pricing-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.item-name {
  font-size: 1.2rem;
  color: #444;
}

.item-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff4500;
}

@media (max-width: 1200px) {
  .pricing-title {
    font-size: 2.2rem;
  }

  .category-title {
    font-size: 1.7rem;
  }
}

@media (max-width: 992px) {
  .pricing-title {
    font-size: 2rem;
  }

  .category-title {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .pricing-title {
    font-size: 1.8rem;
  }

  .category-title {
    font-size: 1.5rem;
  }

  .pricing-item {
    flex-direction: column;
    align-items: center;
  }

  .item-name,
  .item-price {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .pricing-title {
    font-size: 1.6rem;
  }

  .category-title {
    font-size: 1.4rem;
  }

  .pricing-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .pricing-item {
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }

  .item-name,
  .item-price {
    font-size: 1rem;
    margin-bottom: 5px;
  }
}
