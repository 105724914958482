@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
}

.sub_header_astrology {
  background-image: url("../Components/Assets/Astrologybanner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
}

.sub_header_astrology .subheader_inner_astrology {
  padding: 180px 0px 150px;
}

.subheader_inner_astrology {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subheader_text_astrology h1 {
  font-size: 80px;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.sub_header_astrology .breadcrumb {
  padding: 20px 28px;
  margin: 0;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.sub_header_astrology .breadcrumb li {
  margin: 0;
  color: #f1f1f1;
}

.sub_header_astrology .breadcrumb li a {
  position: relative;
  list-style: none;
  text-decoration: none;
  color: #161616;
  font-weight: 700;
  font-size: 14px;
}

.btn-link {
  text-transform: uppercase;
  cursor: pointer;
}

.sub_header_astrology .breadcrumb .breadcrumb-item.active {
  color: #fc6805;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sub_header_astrology .breadcrumb .breadcrumb-item {
  padding-left: 15px;
}

.astrology {
  margin-bottom: 40px;
}

.tp-box {
  border: 1px solid #dadada;
  border-radius: 8px;
  margin: 1px 0;
  overflow: hidden;
  margin-bottom: 15px;
}

.tp-box-content {
  padding: 15px;
}

.tp-box-content h6 {
  color: #212121;
  font-size: 22px;
}

.tp-box-content h6:hover {
  color: #f06412;
}

.tp-box-content .rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tp-box-content .rating .rateshow i {
  color: #358725;
  font-size: 12px;
}

.tp-box-content .rating .ratedescription {
  margin-left: 10px;
  font-size: 14px;
  position: relative;
  top: 2px;
}

.tp-box-content .info {
  color: #1a1a1a;
  font-size: 13px;
  margin-bottom: 0;
}

.tp-box-content .info i {
  margin-right: 5px;
  color: rgb(216, 91, 45);
}

.tp-box-content .price-booknow {
  display: flex;
  text-align: center !important;
}

.tp-box-content .price-booknow .price-group .current-price {
  color: #9d1220;
  font-size: 14px;
}

.tp-box-content .price-booknow .price-group .current-price a {
  color: #212121;
  text-decoration: none;
}

.tp-box-content .price-booknow .booknow-btn {
  background-color: #f06412;
  color: #fff;
  border-radius: 150px;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 15px;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0;
  white-space: nowrap;
  border: none;
}

.astrologyprofile h1 {
  font-size: 2.5rem;
}

.astrologyprofile p {
  font-size: 15px;
  margin-bottom: 19px;
  line-height: 24px;
}

.available_balance {
  display: flex;
  align-items: center;
  font-weight: 350;
}

.balance_avail {
  display: inline-block;
  margin-left: 10px;
}

.filter {
  width: auto;
  font-size: 16px;
  padding: 7px;
  border-radius: 5px;
  background-color: #fff;
  transition: ease-in-out all 0.3s;
  border: 1px solid #ddd;
  color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.filter i {
  font-size: 14px;
  color: #7e7e7e;
  margin-right: 5px;
}

.profile_btn {
  display: flex;
  margin-top: 10px;
}

.astrologer_profile_section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.astrologer_profile_section .border_outline {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
}

.astrologer_profile_picture {
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #ffff;
}

.astrologer_profile_background {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: #ddd;
}

.astrologer_profile_picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.astrologer_name {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333;
}

.astrologer_profession {
  margin-bottom: 10px;
  color: #616161;
}

.astrologer_language {
  font-size: 15px;
  color: #616161;
  margin-bottom: 10px;
  font-weight: 400;
}

.astrologer_experience {
  font-size: 15px;
  color: #616161;
  margin-bottom: 10px;
  font-weight: 400;
}

.price_rate {
  height: 22.5px;
  margin-bottom: 10px;
}

.fees_amount {
  font-size: 15px;
  font-weight: 600;
}

.bold_class {
  font-weight: 600;
  color: #4c4c4c;
}

.bold_class span {
  color: #4c4c4c;
  font-weight: normal;
  font-size: 13px;
}

.chat_call_btns {
  margin-top: 10px;
}

.astrologer_btn_chat {
  display: flex;
  margin: 0 auto;
  box-shadow: 0 0 4px #ccc;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 8px 17px;
  transition: all ease-in-out 0.3s;
  min-height: 55px;
}

.status_chat {
  color: rgb(248, 117, 69) !important;
  background-color: #fff;
  border: 1px solid rgb(248, 117, 69) !important;
}

.chat_btn {
  width: calc(100% - 0px);
  text-align: center;
}

.chat {
  font-size: 16px;
  font-weight: 600;
}

.aboutme_content {
  margin-top: 15px;
}

.aboutme_heading {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
  color: #454545;
}

.aboutme_para {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  overflow: hidden;
  color: #616161;
}

.pandit_section {
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  /* max-height: 100vh; */
}

@media only screen and (max-width: 400px) {
  .sub_header_astrology {
    height: 200px;
  }

  .sub_header_astrology .subheader_inner_astrology {
    padding: 75px 0px 150px;
  }

  .subheader_text_astrology h1 {
    font-size: 40px;
  }

  .sub_header_astrology .breadcrumb {
    padding: 10px 12px;
    bottom: -18px;
  }

  .sub_header_astrology .breadcrumb li a {
    font-size: 12px;
  }

  .sub_header_astrology .breadcrumb .breadcrumb-item.active {
    font-size: 12px;
  }

  .pandit_boxes {
    width: 100%;
    margin-left: 0px;
  }

  .btn_chat {
    padding: 6px 18px;
  }
}

.rechareg_section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.recharge_title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.recharge_balace {
  text-align: center;
  margin-top: 20px;
  color: gray;
}

.avail_balace {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.popular_recharge h1 {
  font-size: 21px;
  margin-top: 40px;
}

.rupee_wallet {
  margin-top: 20px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d4d7d866;
  margin-bottom: 25px;
  font-size: 16px;
  border-radius: 8px;
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  font-weight: 400;
  width: 100%;
  padding: 35px 0;
  box-shadow: 0 0 4px 2px #eee;
}

.rupee_wallet:hover {
  border: 1px solid rgb(248, 92, 35);
}

.rupee_wallet p {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
}

.chatbox {
  margin-top: 80px;
  margin-bottom: 40px;
  margin-left: 100px;
  margin-right: 100px;
  background-image: url("../Components/Assets/chat-img5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  height: 100vh;
  overflow-y: auto;
  position: relative; /* Add this */
}

.message-container {
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
  flex: 1;
  max-height: 90vh;
}

.message-bubble {
  max-width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  /* border-radius: 10px; */
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  word-break: break-word;
  position: relative;
  clear: both;
}

.message-bubble.sent {
  background-color: white;
  color: black;
  width: 200px;
  font-weight: 600;
  align-self: flex-end;
  margin-left: auto;
  /* position: absolute; */
}

.message-bubble.received {
  background-color: white;
  color: black;
  width: 200px;
  font-weight: 600;
  align-self: flex-start;
  margin-right: auto;
}

.input-container {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px;
  position: absolute; /* Change to absolute */
  bottom: 0; /* Fix at the bottom */
  left: 0;
  right: 0;
  background-color: #fafafa; /* Ensure background is set to cover content */
}

.text-input {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
}

.send-button {
  background-color: orangered;
  border: none;
  border-radius: 50px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover {
  background-color: rgba(255, 0, 0, 0.932);
}

.message-text {
  font-size: 16px;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
}

/* .video_box {
  border: 1px solid gray;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
} */

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top */
}

.spinner {
  /* border: 16px solid #f3f3f3; Light grey */
  /* border-top: 16px solid #3498db; Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video_box {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  margin-bottom: 40px; /* Background color to match the video call UI */
}

.localContainer {
  width: 80vw;
  height: 80vh;
  background-color: #000; /* Ensure background is black when video is not available */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.localContainer video {
  width: 900px;
  border-radius: 10px;
}

.remoteContainer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 250px;
  height: 250px;
  background-color: #000;
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
}

.buttonContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 20;
}

.endcall {
  background: rgb(255, 123, 0);
  padding: 5px;
  font-size: 25px;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  margin-left: 15px;
}

@media only screen and (max-width: 480px) {
  .sub_header_astrology .subheader_inner_astrology {
    padding: 75px 0px 60px;
  }
  .subheader_text_astrology h1 {
    font-size: 40px;
  }
  .sub_header_astrology .breadcrumb {
    padding: 10px 5px;
    margin: 0;
    bottom: -22px;
    left: 50%;
  }
  .sub_header_astrology .breadcrumb .breadcrumb-item {
    padding-left: 10px;
  }
  .sub_header_astrology .breadcrumb li a {
    font-size: 12px;
  }
  .sub_header_astrology .breadcrumb .breadcrumb-item.active {
    font-size: 12px;
  }
  .available_balance {
    margin-top: 10px;
  }
  .recharge_btn {
    margin-top: 10px;
  }
  .pandit_section {
    margin-top: -20px;
  }
  .form-control {
    height: 40px;
  }
}

/* .video_box {
  border: 1px solid gray;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
} */

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top */
}

.spinner {
  /* border: 16px solid #f3f3f3; Light grey */
  /* border-top: 16px solid #3498db; Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video_box {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  margin-bottom: 40px;
}

.localContainer {
  width: 80vw;
  height: 80vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.localContainer video {
  width: 900px;
  border-radius: 10px;
}

.remoteContainer {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 250px;
  height: 250px;
  background-color: #000;
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
}

.buttonContainer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 20;
}

.endcall {
  background: rgb(255, 123, 0);
  padding: 5px;
  font-size: 25px;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  margin-left: 15px;
}
