.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px 40px;
}

.error-icon {
  margin-top: 40px;
  font-size: 20px;
  color: #ff4d4d;
}

.prasad-error {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.no-bookings {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
}

.booking-page {
  padding: 20px;
  background-color: #f9f9f9;
}

.page-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.booking-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.booking-card {
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 15px;
}

.booking-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yoga-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.price {
  font-size: 16px;
  color: #4caf50;
  margin-bottom: 10px;
}

.order-date {
  font-size: 14px;
  color: #555;
}

.no-bookings {
  text-align: center;
  font-size: 16px;
  color: #888;
}

@media screen and (max-width: 575.98px) {
  .booking-card {
    flex-direction: column;
    text-align: center;
  }

  /* .error-icon {
    margin-bottom: 10px;
    margin-left: 150px;
  } */
}
