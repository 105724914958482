.order-detail-page {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.page-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.decorative-line {
  width: 120px;
  height: 2px;
  background-color: #ff6b6b;
  margin: 10px auto;
  border-radius: 2px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  width: 200px;
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.product-card:hover {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 10px;
}

.product-info {
  text-align: center;
}

.product-name {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
}

.product-price {
  font-size: 14px;
  color: #555555;
}

.product-price span {
  color: #ff6b6b;
  font-weight: bold;
}

.product-quantity {
  font-size: 14px;
  color: #444444;
}

.loading-container,
.error-container,
.no-products {
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  color: #555555;
}

.loading {
  color: #ff6b6b;
}
.order-content {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.product-list-container {
  flex: 2;
}

.bill-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.bill-summary {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 60px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.bill-summary:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

.bill-summary h2 {
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #ff6b6b;
  padding-bottom: 10px;
  font-weight: 700;
}

.bill-summary p,
.bill-summary li {
  font-size: 14px;
  color: #555555;
  margin-bottom: 12px;
}

.bill-summary ul {
  list-style: none;
  padding: 0;
}

.bill-summary li {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}

.bill-summary li span {
  font-weight: bold;
  color: #444444;
}

.total-highlight {
  font-size: 18px;
  font-weight: bold;
  color: #ff6b6b;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  letter-spacing: 0.5px;
}

.total-highlight span {
  color: #333333;
  font-weight: 700;
}

.bill-footer {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  color: #777777;
  font-style: italic;
}

@media (max-width: 768px) {
  .order-content {
    flex-direction: column;
    gap: 20px;
  }

  .product-card {
    width: 180px;
  }

  .product-image {
    width: 180px;
    height: 120px;
  }

  .bill-summary {
    padding: 40px;
    max-width: 100%;
  }

  .page-title {
    font-size: 24px;
  }

  .product-name {
    font-size: 12px;
  }

  .product-price,
  .product-quantity {
    font-size: 12px;
  }

  .total-highlight {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .order-detail-page {
    padding: 15px;
  }

  .product-card {
    width: 160px;
  }

  .product-image {
    width: 160px;
    height: 100px;
  }

  .bill-summary {
    padding: 30px;
  }

  .page-title {
    font-size: 20px;
  }

  .product-name {
    font-size: 10px;
  }

  .product-price,
  .product-quantity {
    font-size: 10px;
  }

  .total-highlight {
    font-size: 14px;
  }
}
