.pandit-profile {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 60px auto;
}

.profile-container-pandit {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Profile Image */
.profile-image-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Adjusts the flex-basis to prevent the image from being too large */
  max-width: 300px; /* Limit the container width */
}

.profile-image {
  width: 300px; /* Fixed width */
  height: 300px; /* Fixed height */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  border-radius: 12px;
  border: 4px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Prevents the image from stretching */
}

/* Profile Details */
.profile-details-column {
  flex: 2;
}

.profile-details {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.profile-name {
  font-size: 28px;
  margin: 0 0 15px;
  color: #333333;
  font-weight: bold;
}

.profile-description {
  font-size: 16px;
  color: #666666;
  margin-bottom: 20px;
}

/* Action Buttons */
.action-buttons-row {
  display: flex;
  gap: 15px;
  flex-wrap: wrap; /* Allows wrapping of buttons on smaller screens */
}

.action-button-column {
  flex: 1;
}

.action-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_button {
  background-color: #007bff;
}

.voice-call-button {
  background-color: #28a745;
}

.video-call-button {
  background-color: #dc3545;
}

.action-button:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.action-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button-text {
  font-weight: bold;
}

/* Enquire Button */
.enquire-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #17a2b8;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.enquire-button:hover {
  background-color: #138496;
  transform: scale(1.02);
}

/* Loader */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-spinner img {
  width: 60px;
  height: 60px;
}

/* Error and Loading Messages */
.loading-message,
.error-message,
.no-data-message {
  text-align: center;
  font-size: 18px;
  color: #333333;
  padding: 20px;
}

.error-message {
  color: #dc3545;
}

.no-data-message {
  color: #6c757d;
}

/* Responsive Styles */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .profile-row {
    flex-direction: column;
  }

  .profile-image-column {
    max-width: 100%; /* Ensure the column width is full */
  }

  .profile-image {
    width: 300px; /* Fixed width */
    height: 300px; /* Fixed height */
  }

  .profile-details {
    padding: 15px;
  }

  .profile-name {
    font-size: 24px;
  }

  .profile-description {
    font-size: 14px;
  }

  .action-buttons-row {
    flex-direction: column;
    gap: 10px;
  }

  .action-button {
    font-size: 14px;
  }

  .enquire-button {
    font-size: 14px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .profile-name {
    font-size: 20px;
  }

  .profile-description {
    font-size: 12px;
  }

  .action-button {
    font-size: 12px;
    padding: 10px;
  }

  .enquire-button {
    font-size: 12px;
    padding: 10px;
  }

  .loader-spinner img {
    width: 50px;
    height: 50px;
  }
}
