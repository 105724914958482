.text-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.quantity-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
}

.quantity-picker {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f9f9f9;
}

.text-input-container input:focus,
.text-input-container select:focus {
  outline: none;
  border-color: orange;
  box-shadow: 0 0 5px rgba(255, 81, 0, 0.5);
}

.quantity-picker {
  cursor: pointer;
}

.text-input-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and dropdown */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 100%;
  max-width: 400px; /* Adjust as per your needs */
}

.quantity-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.quantity-picker {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.prasad_section {
  padding: 0px 0px;
  /* margin: 20px !important; */
  margin-bottom: 40px;
}

.prasad_content {
  margin-top: 40px;
}

.prasad_content h1 {
  font-size: 2.5rem;
}

.prasad_content p {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 24px;
}

.prasad_content ul {
  padding: 0px 0px 0px 20px;
  margin: 0 0 15px 0;
}

.prasad_content ul li {
  list-style-type: disc;
  font-size: 15px;
  margin-bottom: 8px;
}

.package_transport img {
  max-width: 600px;
  width: 100%;
  margin: 0.5rem;
  float: right;
}

.package_form {
  padding: 25px;
  border-radius: 8px;
  border: 2px dashed gray;
}

.form-group {
  margin-bottom: 15px;
}

.form-group .input {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0px 15px;
  font-size: 15px;
  color: #000;
}

.form-group label {
  font-size: 15px;
  color: #fc6908;
  font-weight: 600;
}

.form-group .form-control .inputbox-line {
  padding: 0;
  border-radius: 0;
  margin-top: 10px;
}

.form-group .form-control .inputbox-line {
  border: 0;
  border-bottom: 2px solid #333;
  padding: 0;
  border-radius: 0;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .prasad_content h1 {
    font-size: 1.5rem;
  }
}
