/* panditkicss.css */
/* Services Section Styles */
.services-section {
  padding: 40px 20px;
  background-color: white; /* Light Orange */
}

.services-container {
  max-width: 1200px;
  margin: 20px auto;
}

.services-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.service-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Set the height of the profile image container */
}

.service-image img {
  width: 120px; /* Set the width of the profile image */
  height: 120px; /* Set the height of the profile image */
  object-fit: cover;
  border-radius: 50%; /* Makes the image circular */
  border: 4px solid rgb(248, 117, 69); /* Orange Border */
}

.service-details {
  padding: 20px;
}

.service-name {
  font-size: 1.6rem;
  margin: 0;
  color: #333;
}

.service-name a {
  color: black; /* Deep Orange */
  text-decoration: none;
  font-weight: 600;
}

.service-name a:hover {
  text-decoration: underline;
  color: red; /* Darker Orange */
}

.service-rating {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.rating-stars i {
  color: #ffb300; /* Yellow */
  margin-right: 3px;
}

.rating-info {
  margin-left: 10px;
  font-size: 0.875rem;
  color: #757575; /* Dark Gray */
}

.service-language,
.service-experience,
.service-price {
  margin: 10px 0;
  font-size: 0.875rem;
}

.service-language i,
.service-experience i,
.service-price i {
  margin-right: 5px;
  color: #d85b2d; /* Deep Orange */
}

.service-action {
  /* Add styles for the service action section here */
}

.enquire-btn {
  display: inline-block;
  padding: 4px 8px;
  background-color: rgb(248, 117, 69); /* Deep Orange */
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.enquire-btn:hover {
  background-color: red; /* Darker Orange */
}

/* Responsive Styles */

/* Mobile Styles */
@media (max-width: 768px) {
  .services-row {
    flex-direction: column;
  }

  .service-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .service-image img {
    width: 100px;
    height: 100px;
  }

  .service-name {
    font-size: 1.4rem;
  }

  .rating-info {
    font-size: 0.75rem;
  }

  .service-language,
  .service-experience,
  .service-price {
    font-size: 0.75rem;
  }
}

/* Tablet Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .services-row {
    flex-direction: row;
  }

  .service-card {
    width: calc(33.333% - 20px); /* Three cards per row with gap */
  }

  .service-image img {
    width: 110px;
    height: 110px;
  }

  .service-name {
    font-size: 1.5rem;
  }

  .rating-info {
    font-size: 0.8rem;
  }

  .service-language,
  .service-experience,
  .service-price {
    font-size: 0.8rem;
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .service-card {
    width: calc(33.333% - 20px);
  }
}
