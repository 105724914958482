.sub_header_about {
  background-image: url("../Components/Assets/aboutusbanner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
}

.sub_header_about .subheader_inner_about {
  padding: 200px 0px 180px;
}

.subheader_inner_about {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.subheader_text_about h1 {
  font-size: 70px;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  text-align: left;
}

.sub_header_about .breadcrumb {
  padding: 20px 28px;
  margin: 0;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.sub_header_about .breadcrumb li {
  margin: 0;
  color: #f1f1f1;
}

.sub_header_about .breadcrumb li a {
  position: relative;
  list-style: none;
  text-decoration: none;
  color: #161616;
  font-weight: 700;
  font-size: 14px;
}

.btn-link {
  text-transform: uppercase;
  cursor: pointer;
}

.sub_header_about .breadcrumb .breadcrumb-item.active {
  color: #fc6805;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sub_header_about .breadcrumb .breadcrumb-item {
  padding-left: 15px;
}

.about_content {
  padding-top: 80px;
  padding-bottom: 80px;
}

.title_about {
  padding-left: 50px;
  padding-bottom: 30px;
  padding-top: 50px;
}

.title_about h1 {
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fa7b04;
  font-weight: 700;
}

.title_about h4 {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  font-weight: 600;
}

.subtitle {
  padding-left: 50px;
}

.subtitle h4 {
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: uppercase;
  color: #85817d;
  font-weight: 700;
}

.subtitle p {
  text-align: justify;
  line-height: 25px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Lancelot", serif;
}

.about-btn1 {
  margin-left: 50px !important;
  padding: 14px 16px !important;
  font-size: 16px !important;
  font-weight: 600;
  margin-top: 35px;
  background-color: #f37443;
  color: #fff;
  border-radius: 5px;
}

.about-btn1:hover {
  background: orangered;
  color: white;
}

.service_list {
  padding-bottom: 50px;
}

.whychoose_us {
  padding-bottom: 50px;
}

.community {
  padding-bottom: 30px;
}

.contact-us {
  padding-bottom: 80px;
}

.service_title h1 {
  padding-bottom: 15px;
  line-height: 30px;
  font-weight: 600;
  font-size: 20px;
}

/*.service_title h1::after {
  content: "";
  width: 65px;
  height: 3px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #f87408;
} */

.service_list li span {
  font-weight: 600;
}

.service_list li {
  color: rgb(90, 90, 90);
}

.whychoose_us_title h1 {
  padding-bottom: 15px;
  line-height: 30px;
  font-weight: 600;
  font-size: 20px;
}

.whychoose_list li span {
  font-weight: 600;
}

.whychoose_list li {
  color: rgb(90, 90, 90);
}

.community_title h1 {
  padding-bottom: 15px;
  line-height: 30px;
  font-weight: 600;
  font-size: 20px;
}

.community_title p {
  color: rgb(90, 90, 90);
  font-weight: 600;
}

.contact_heading h1 {
  padding-bottom: 15px;
  line-height: 30px;
  font-weight: 600;
  font-size: 20px;
}

.contact_heading p {
  color: rgb(90, 90, 90);
  font-weight: 600;
}

.aboutsectionimg {
  width: 550px;
}

@media only screen and (max-width: 1024px) {
  .about_content {
    padding-top: 20px;
  }
  .title_about {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: justify;
  }
  .subtitle {
    padding-left: 20px;
  }
  .about-btn1 {
    margin-left: 20px !important;
    padding: 8px 6px !important;
    font-size: 14px !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .sub_header_about .subheader_inner_about {
    padding: 40px 0px 40px !important;
  }
  .subheader_text_about h1 {
    font-size: 25px !important;
  }
  .sub_header_about .breadcrumb {
    padding: 10px 5px !important;
    margin: 0;
    bottom: -22px !important;
    left: 50%;
  }
  .sub_header_about .breadcrumb .breadcrumb-item {
    padding-left: 10px;
  }
  .sub_header_about .breadcrumb li a {
    font-size: 12px !important;
  }
  .sub_header_about .breadcrumb .breadcrumb-item.active {
    font-size: 12px !important;
  }

  .about_content {
    padding-top: 0px;
  }

  .title_about {
    text-align: justify;
    padding-left: 10px;
    padding-top: 20px;
  }

  .subtitle {
    padding-left: 10px;
  }

  .about-btn1 {
    margin-left: 10px !important;
  }

  .services_section {
    text-align: justify;
  }

  .whychoose_us {
    text-align: justify;
  }

  .community {
    text-align: justify;
  }

  .contact-us {
    text-align: justify;
  }
}
