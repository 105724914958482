.overlaypop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay effect */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.loginPopup {
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.closeBtn {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

.ic--baseline-close {
  display: inline-block;
  width: 22px;
  height: 22px;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.loginLogoImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.addUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.065);
  position: relative;
}
.addUserForm .inputGroup {
  width: 100%;
}

.addUserForm .inputGroup1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.addUserForm .inputGroup label {
  margin-top: 10px;
  font-weight: 600;
}

.addUserForm .inputGroup input {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #bbb5b5;
  border-radius: 10px;
}

.inputGroup button {
  width: 50%;
  margin: 0 10px;
}
.login {
  margin-top: 15px;
  text-align: center;
}

.login .btn.btn-success {
  width: 50%;
  border: none;
  outline: none;
  background: rgb(248, 117, 69);
}

.addUser h3 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: rgb(248, 117, 69);
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.inputfile {
  display: none;
}

.profileImageLabel {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.profileImageLabel img {
  display: block;
  border-radius: 50%;
  border: 2px solid #ccc;
  padding: 5px;
}

.profileImageLabel input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.addUserForm {
  width: 100%;
  /* padding: 20px 30px; */
}

.addUserForm .inputGroup input:focus {
  outline: none;
}

.profile_section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.profile_box {
  border: 1px solid #d4d7d866;
  width: 200px;
  padding: 15px;
  box-shadow: 0 0 4px 2px #eee;
  background-color: white;
}

.profile_img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-left: 50px;
}

.profile_titlee {
  text-align: center;
  margin-top: 10px;
  color: rgb(248, 117, 69);
  font-weight: 500;
}

.profile_logoutbtn {
  text-align: center;
  margin-top: 5px;
}

.login-button {
  padding: 10px 10px !important;
  width: 30%;
}

.bookerror {
  color: red;
  font-size: 12px;
}

.resend-otp {
  cursor: pointer;
  color: #074486;
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  .addUser {
    width: 100%;
  }
  .addUser h3 {
    font-size: 22px;
    margin-top: 20px;
  }
}

/* /////////////// otp container///////////// */

.otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.otp-box {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.otp-box:focus {
  border-color: #007bff;
}

.errorOtp {
  color: red;
  margin-top: 5px;
  margin-bottom: 10px;
}

.inputGroupOtp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .loginPopup {
    width: 100%;
    max-width: 700px;
  }
  .addUserForm .inputGroup1 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .inputGroup button {
    width: 100%;
    border-radius: 10px;
    margin-top: 15px;
  }

  .addUserForm .inputGroup label {
    font-size: 14px;
  }
  .addUserForm .inputGroup input {
    font-size: 12px;
  }
}
