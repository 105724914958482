/* .our_team_section {
  background-color: #fff;
  padding: 50px 0;
}

.team_box {
  text-align: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.team_box:hover {
  transform: translateY(-10px);
}

.team_img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
  object-fit: cover;
}

.team_name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.team_role {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.our_team_section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.our_team_section p {
  font-size: 1rem;
  color: #1a2b3c;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
} */

/* body {
  font-family: tahoma;
  height: 100vh;
  background-image: url(https://picsum.photos/g/3000/2000);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
} */

.our_team_section {
  background-color: #fff;
  padding: 50px 0;
}

.our_team_section h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 700;
  color: #000;
  text-align: center;
}

.our_team_section p {
  font-size: 15px;
  width: 80%;
  margin: auto;
  color: #333;
  font-weight: 400;
  margin-bottom: 40px;
  text-align: center;
}

.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #cd5702;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #cd5702;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #cd5702;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
}
/* 
.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
} */

.our-team .social li a:hover {
  color: #1369ce;
  background-color: #f7f5ec;
}
