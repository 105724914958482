.sub_header1 {
  background-image: url("../Components/Assets/yogaa.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
  /* height: 500px; */
}

.sub_header1 .subheader_inner {
  padding: 180px 0px 150px;
}

.subheader_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subheader_text1 h1 {
  font-size: 70px;
  margin-bottom: 0;
  font-weight: 700;
  color: darkorange;
  text-align: center;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
}

.sub_header1 .breadcrumb {
  padding: 20px 28px;
  margin: 0;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.sub_header1 .breadcrumb li {
  margin: 0;
  color: #f1f1f1;
}

.sub_header1 .breadcrumb li a {
  position: relative;
  list-style: none;
  text-decoration: none;
  color: #161616;
  font-weight: 700;
  font-size: 14px;
}

.btn-link {
  text-transform: uppercase;
  cursor: pointer;
}

.sub_header1 .breadcrumb .breadcrumb-item.active {
  color: #fc6805;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sub_header1 .breadcrumb .breadcrumb-item {
  padding-left: 15px;
}

.tp-box-content h6 {
  font-size: 1.25rem;
  line-height: 24px;
  /* margin: 0 0 8px !important; */
  padding: 0;
  font-weight: 700;
  font-size: 18px;
  color: #3f6e71;
  transition: all 0.4s ease;
}

.tp-box-content p {
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px !important;
  /* margin-bottom: 16px !important; */
  color: #343d3c;
  transition: all 0.4s ease;
}

/* yoga series*/

/* Base styles */
.therapeutic-yoga-series {
  padding: 0px 20px;
  background-color: white;
}

.therapeutic-yoga-series h2 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 2.5em;
  color: darkorange;
}

.therapeutic-yoga-series p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
}

.yoga-series {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.yoga-item {
  width: 45%;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;

  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.yoga-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.yoga-item img {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.yoga-item h3 {
  margin-top: 15px;
  font-size: 1.5em;
  color: darkorange;
}

.yoga-item p {
  font-size: 1.1em;
  line-height: 1.6;
}

.bookbtnyoga {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.bookbtnyoga button {
  margin: 10px;
  background: rgb(248, 117, 69);
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  width: 35%;
  border-radius: 5px;
}

.bookbtnyoga button:hover {
  color: white;
  background: rgb(243, 32, 5);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .yoga-item {
    width: 48%;
  }
}

@media (max-width: 992px) {
  .therapeutic-yoga-series h2 {
    font-size: 2em;
  }

  .therapeutic-yoga-series p {
    font-size: 1em;
  }

  .yoga-item {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .sub_header1 .subheader_inner {
    padding: 90px 0px 70px;
  }
  .subheader_text1 h1 {
    font-size: 30px;
  }
  .sub_header1 .breadcrumb {
    padding: 12px 16px;
    bottom: -25px;
  }
  .therapeutic-yoga-series h2 {
    font-size: 1.8em;
  }

  .therapeutic-yoga-series p {
    font-size: 0.9em;
  }

  .yoga-item {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .therapeutic-yoga-series h2 {
    font-size: 1.6em;
  }

  .therapeutic-yoga-series p {
    font-size: 0.8em;
  }

  .yoga-item {
    width: 100%;
    padding: 10px;
  }

  .yoga-item h3 {
    font-size: 1.3em;
  }
}
