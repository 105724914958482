.order-page {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.page-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.decorative-line {
  width: 120px;
  height: 2px;
  background-color: #ff6b6b;
  margin: 10px auto;
  border-radius: 2px;
}

.order-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.order-card {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  transition: box-shadow 0.3s ease;
}

.order-card:hover {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.order-id {
  font-size: 18px;
  font-weight: bold;
}

.order-date {
  font-size: 14px;
  color: #888888;
}

.order-price {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 10px 0;
}

.product-details {
  margin-top: 10px;
}

.product-item {
  font-size: 14px;
  color: #555555;
}
.images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.order-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.quantity-badge {
  position: absolute;
  top: -8px;
  right: -5px;
  background-color: black;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 3px;
  border-radius: 50%;
  min-width: 20px;
  text-align: center;
}

.order-image:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .order-card {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .page-title {
    font-size: 24px;
  }
}
