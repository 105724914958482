#google_translate_element {
    z-index: 1000;
    overflow: hidden !important;
    /* color: transparent;
    background: transparent !important; */
    width: 20px;
    height: 20px;
  }
  #google_translate_element:focus{
    outline: none !important;
  }
  
  #google_translate_element a {
    display: none;
  }
  
  .goog-te-combo {
    width: 100% !important;
    background: transparent !important;
    cursor: pointer;

  }
  
  .goog-te-gadget {
    font-size: 0px;
    background: transparent !important;

  }
  
  .skiptranslate,
  .goog-te-gadget {
    width: 20px;
    height: 24px;

  }
  
 
  
  .goog-te-gadget img {
    display: none !important;
  }
  
  .goog-logo-link {
    display: none !important;
  }

  select.goog-te-combo:focus {
    outline: none;
}
  
.uil--language {
  display: inline-block;
  width: 24px;
  height: 24px;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M21.056 12h-2a1 1 0 0 0 0 2v2H17.87a3 3 0 0 0 .185-1a3 3 0 0 0-5.598-1.5a1 1 0 1 0 1.732 1a1 1 0 0 1 .866-.5a1 1 0 0 1 0 2a1 1 0 0 0 0 2a1 1 0 1 1 0 2a1 1 0 0 1-.866-.5a1 1 0 1 0-1.732 1a3 3 0 0 0 5.598-1.5a3 3 0 0 0-.185-1h1.185v3a1 1 0 0 0 2 0v-7a1 1 0 1 0 0-2m-11.97-.757a1 1 0 1 0 1.94-.486l-1.757-7.03a2.28 2.28 0 0 0-4.425 0l-1.758 7.03a1 1 0 1 0 1.94.486L5.585 9h2.94ZM6.086 7l.697-2.787a.292.292 0 0 1 .546 0L8.026 7Zm7.97 0h1a1 1 0 0 1 1 1v1a1 1 0 0 0 2 0V8a3.003 3.003 0 0 0-3-3h-1a1 1 0 0 0 0 2m-4 9h-1a1 1 0 0 1-1-1v-1a1 1 0 0 0-2 0v1a3.003 3.003 0 0 0 3 3h1a1 1 0 0 0 0-2'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}