@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* .hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #ffccf9, #ffeb3b, #ffffff);
  padding: 50px;
  height: 60vh;
  overflow: hidden;
} */

.loading-icon {
  text-align: center;
}

body {
  font-family: "Poppins", sans-serif;
}

.hero-image {
  flex: 1;
  animation: slideInLeft 1.5s ease;
}

.hero-image img {
  max-width: 100%;
  border-radius: 10px;
  object-fit: cover;
  /* background-position: center; */
  height: 100%;
}

.hero-text {
  flex: 1;
  color: #333;
  animation: fadeInRight 2s ease;
  padding: 0 20px;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-text h6 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #555;
}

.hero-text p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.btn-primary {
  padding: 10px 20px;
  background-color: #ff7a59;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 1rem;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #ff5733;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.item img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.item-img {
  background-repeat: no-repeat;
}

.slick-prev {
  display: none !important;
}

.slick-next {
  display: none !important;
}

.bg-image {
  background-image: url("../Components/Assets/PrabhuPujaherobanner1.webp");
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* .slick-dots {
  position: absolute;
  bottom: 15px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
} */

/* product */
.featured-pujas-carousel {
  padding: 40px 0;
  text-align: center;
  position: relative;
}

.section-title-product {
  font-size: 2.2rem;
  margin-bottom: 2.5rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
}

.puja-item {
  position: relative;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  margin: 20px 15px;
  padding-bottom: 15px;
  width: 90%;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s ease-in-out;
}

.puja-item:hover {
  transform: scale(1.05);
}

.image-container1 {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.puja-image {
  width: 100%;
  height: 260px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.puja-item:hover .puja-image {
  transform: scale(1.1);
}

.puja-details {
  padding: 5px;
  text-align: center;
}

.puja-details h3 {
  font-size: 1.1rem;
  color: #1a2b3c;
  margin: 5px 0;
  font-weight: 600;
}

/* .puja-details p {
  text-align: center;
  font-size: 1rem;
  color: #777;
  margin-bottom: 10px;
} */

.puja_current_price {
  text-align: center;
  font-size: 15px;
  color: #777;
  /* margin-bottom: 10px; */
  text-decoration: line-through;
}

.puja_final_price {
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

.puja_price {
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: #000;
  margin-top: 8px;
  margin-bottom: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff5100;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  background-color: #fa7223;
  transform: translateY(-3px);
}

.arrow1 {
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #f9f8f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.arrow1:hover {
  background-color: #ffb56b;
  color: white;
}

.next {
  right: -50px;
}

.prev {
  left: -50px;
}

.slick-prev {
  left: 25px !important;
  margin-top: 10px !important;
}

.slick-next {
  right: 25px !important;
  margin-top: 10px !important;
}

@media (max-width: 576px) {
  .next {
    right: -10px;
  }

  .prev {
    left: -10px;
  }

  .section-title-product {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .puja-item {
    max-width: 320px;
  }
}

.productname {
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
}

/* testimonial */
.testimonial-carousel {
  text-align: center;
  margin: 50px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
}

.testimonial-header {
  color: orange;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.testimonial-experience {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
}

.testimonial-text {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.testimonial-author {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
}

.testimonial-stars {
  font-size: 20px;
  color: gold;
  text-align: center;
  margin-top: 10px;
}

.testimonial-stars .empty {
  color: #ccc;
}

.slick-dots {
  bottom: -25px !important;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ccc;
}

.slick-dots li.slick-active button:before {
  color: orange;
  font-size: 16px;
}

.slick-dots li button:hover:before {
  color: #ff9800;
  transform: scale(1.2);
  transition: all 0.3s ease;
}

.decorative-linee {
  width: 120px;
  height: 2px;
  background: orange;
  margin-right: auto;
  margin-left: auto;
  margin-top: -12px;
  margin-bottom: 20px;
  border-radius: 5px;
}

/* why choose us start */
/* .why-choose-us {
  padding: 40px 20px;
  background-color: #f8f8f8;
  font-family: "Roboto", sans-serif;
}

.section-title-whychooseus {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.section-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #f96302;
  margin-bottom: 15px;
  text-align: left;
}

.feature-list,
.trust-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.feature-item,
.trust-item {
  margin-bottom: 20px;
  border-left: 3px solid #f96302;
  padding-left: 15px;
  gap: 10px;
}

.feature-title,
.trust-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #222;
  margin: 0 0 5px;
}

.feature-description,
.trust-description {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
  margin: 0;
}
@media (max-width: 768px) {
  .section-title {
    font-size: 1.8rem;
  }
  .section-subtitle {
    font-size: 1.3rem;
  }

  .feature-title,
  .trust-title {
    font-size: 1rem;
  }
  .feature-description,
  .trust-description {
    font-size: 0.85rem;
  }
} */

/* blog section css */
/* .blog-section {
  padding: 60px 20px;
  text-align: center;
}

.blog-title {
  font-size: 2rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.blog-subtitle {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.blog-card {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 20px;
}

.blog-heading {
  font-size: 1rem;
  color: #222;
  margin-bottom: 10px;
  font-weight: 600;
}

.blog-description {
  font-size: 15px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.blog-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: rgb(248, 117, 69);
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.blog-button:hover {
  background-color: rgb(243, 32, 5);
  transform: scale(1.05);
} */

.blog-section {
  padding: 60px;
  text-align: left;
}

.blog-section h1 {
  font-size: 35px;
  font-weight: bold;
  margin: 0;
}

.highlight {
  color: #d75701;
}

.subtitle_blog {
  font-size: 16px;
  margin: 10px 0 20px 0;
  color: #666;
}

.view-all {
  text-decoration: none;
  color: #d75701;
  font-weight: bold;
  margin-left: 10px;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.blog-card {
  flex: 1 1 calc(33.333% - 20px);
  max-width: 290px;
  border-radius: 10px;
  margin-left: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.blog-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.blog-content1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 15px;
  background: #fcecd8;
}

.blog-content1 h3 {
  font-size: 15px;
  margin: 0;
  color: #000;
  font-weight: 600;
}

.blog-content1 p {
  color: #2e2e2e;
  font-size: 14px;
  padding-top: 10px;
}

.blog-info {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.author-details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.author-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.blog-author {
  font-size: 14px;
  color: #333;
}

.blog-date {
  font-size: 12px;
  color: #888;
}

@media (max-width: 768px) {
  .blog-section h1 {
    font-size: 24px;
  }

  .subtitle_blog {
    font-size: 13px;
  }

  .blog-card {
    flex: 1 1 calc(50% - 20px);
  }

  .blog-content1 {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .blog-section {
    padding: 20px;
  }
  .blog-section h1 {
    font-size: 25px;
    text-align: center;
  }

  .subtitle_blog {
    font-size: 14px;
    text-align: center;
    margin: 5px 0px 30px 0;
  }

  .blogs-container {
    gap: 15px;
  }

  .blog-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .blog-image {
    height: 120px;
  }
}

/* review css start */
.customer-section {
  background-color: #fef4e8;
  padding: 50px 0;
  text-align: left;
}

.customer-container {
  width: 80%;
  margin: 0 auto;
}

.customer-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #000;
}

.customer-header .highlight {
  color: #cd5701;
}

.customer-header p {
  font-size: 0.9rem;
  width: 50%;
  color: #1a2b3c;
  font-weight: 500;
  margin-bottom: 40px;
}

.customer-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.customer-card {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.customer-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.customer-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.text-info {
  display: flex;
  flex-direction: column;
}

.customer-card h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #cd5701;
}

.customer-card p {
  color: #4a4141;
  font-size: 14px;
}

.customer-card .rating {
  color: #ff6700;
  margin-top: -12px;
}

.customer-card .review {
  font-size: 13px;
  color: #332d2d;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .customer-header h2 {
    font-size: 1.8rem;
  }

  .customer-header p {
    font-size: 0.85rem;
  }

  .customer-card {
    width: 45%;
  }
}

@media (max-width: 576px) {
  .customer-header h2 {
    font-size: 1.6rem;
  }

  .customer-header p {
    font-size: 0.8rem;
    width: 100%;
  }

  .customer-card {
    width: 100%;
  }
}

/* Newsletter CSS start */
.why-choose-us-container {
  margin-left: 180px;
  margin-right: 180px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 40px;
  padding-left: 40px;
  position: relative;
  z-index: -1;
  max-width: 1200px;
  background-color: #fcecd8;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.content-column {
  flex: 1;
  margin-bottom: 20px;
  text-align: center;
}

.why-choose-us-heading {
  font-size: 1.9rem;
  font-weight: 800;
  margin-bottom: 10px;
  color: #2e2e2e;
  text-align: left;
}

.why-choose-us-description {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #1a2b3c;
  line-height: 1.5;
  max-width: 550px;
  margin-top: 10px;
}

.why-choose-us-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  margin-top: 30px;
}

.features-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-item_newsletter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}

.feature-item_newsletter p {
  color: #2e2e2e;
}

.icon-background {
  background-color: #ff6600;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-icon_newsletter {
  color: white;
  font-size: 1.2rem;
}

.newsletter-container {
  margin-top: 30px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: -10px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 215px;
  /* padding: 15px; */
  text-align: center;
  background-color: #d2a34c;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}

.newsletter-container h3 {
  font-size: 25px;
  font-weight: 700;
  color: #2e2e2e;
  text-align: left;
  margin: 0;
  white-space: nowrap;
}

.newsletter-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
}

.newsletter-input {
  padding: 8px 12px;
  font-size: 12px;
  background: #fcecd8;
  /* border: 1px solid #ddd; */
  color: #919191;
  border-radius: 1px;
  width: 200px;
  max-width: 100%;
  box-sizing: border-box;
}

.newsletter-button {
  padding: 8px 15px;
  margin-left: 20px;
  letter-spacing: 2px;
  background-color: #a70000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.newsletter-button:hover {
  background-color: #c80b0b;
}

@media (max-width: 1200px) {
  .why-choose-us-container {
    margin: 20px 40px;
    padding: 15px;
  }

  .why-choose-us-row {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .why-choose-us-heading {
    font-size: 1.6rem;
    text-align: center;
  }

  .why-choose-us-description {
    text-align: center;
  }

  .why-choose-us-row {
    flex-direction: column;
    gap: 20px;
  }

  .newsletter-container h3 {
    font-size: 16px;
    text-align: center;
  }

  .newsletter-inputs {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 575.98px) {
  .why-choose-us-container {
    margin: 10px;
    padding: 10px;
  }

  .why-choose-us-heading {
    font-size: 1.8rem;
  }

  .newsletter-container h3 {
    font-size: 20px;
    margin-bottom: 40px;
  }

  .newsletter-inputs {
    flex-direction: column;
    gap: 12px;
  }

  .newsletter-input {
    font-size: 12px;
    width: 100%;
  }

  .newsletter-button {
    font-size: 0.7rem;
    padding: 10px 10px;
    margin-left: 0;
  }

  .newsletter-container {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    padding-right: 0px;
    gap: 10px;
    border-radius: 10px;
  }
}

/* problem section css start */
.solve-problems-section {
  text-align: center;
  padding: 4rem;
}

.solve-problems-title {
  font-size: 2.2rem;
  margin-bottom: 2.5rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
}

.problems-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-content: center;
  gap: 2rem;
}

.problem-card {
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.problem-card:hover {
  transform: translateY(-10px);
}

.problem-image-container {
  width: 150px;
  height: 150px;
  margin: 0 auto 1rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.problem-image {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.problem-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: #332d2d;
  margin-top: 0.5rem;
}

@media (max-width: 1200px) {
  .solve-problems-title {
    font-size: 2rem;
  }

  .problem-title {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .solve-problems-title {
    font-size: 1.8rem;
  }

  .problem-title {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .solve-problems-title {
    font-size: 1.6rem;
  }

  .problem-title {
    font-size: 0.95rem;
  }

  .problem-image-container {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 576px) {
  .problem-card {
    margin-bottom: -35px;
  }
  .solve-problems-title {
    font-size: 1.8rem;
  }

  .problem-title {
    font-size: 0.9rem;
  }

  .problems-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .problem-image-container {
    width: 120px;
    height: 120px;
  }

  .problem-title {
    font-size: 0.8rem;
  }
}

/*  about us css  */

.about-img {
  height: 500px;
  border-radius: 5px;
  width: 100%;
  max-width: 800px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.about-img:hover {
  transform: scale(1.02) translateY(-10px);
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); */
}

.about {
  padding-bottom: 50px;
  margin-top: 40px;
}

.title {
  padding-top: 40px;
  /* padding-left: 50px; */
  position: relative;
}

.title h1 {
  color: #d05100;
  line-height: 40px;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 1rem;
  /* display: inline-block; */
  position: relative;
}

/* .title h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 60px;
  height: 2px;
  background-color: #000;
}

.title h2::before {
  left: -80px;
} */
/* 
.title h2::after {
  right: -80px;
} */

.title h5 {
  line-height: 30px;
  font-weight: 500;
  font-size: 20px;
}

.title h5 span {
  color: #f37443;
}

.title p {
  color: #4a4141;
  padding-top: 10px;
  font-weight: 400;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
}

.about-btn {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #d05100;
  border: 2px solid #d75502;
  background: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s ease;
}

.about-btn:hover {
  background: #fcecd8;
  color: #d05100;
}

/* About.css */

/*  services css start  */

.services {
  /* padding-top: 80px; */
  padding-bottom: 80px;
}

.bappaimgsection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.services-title h1 {
  font-size: 2.2rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
}

.bappaimg {
  height: 70px;
  width: 70px;
}

.table-box {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.table-box .box {
  border-radius: 10px;
  margin: 10px;
  width: 250px;
}

.box {
  text-align: center;
  border: 1px solid #dadada;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.box.move-up {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.box a {
  color: #292929;
  text-decoration: none;
}

.box img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
  height: 65px;
  transition: transform 0.3s ease;
}

.box:hover img {
  transform: scale(1.05);
}

.table-box .box p {
  font-size: 14px;
  padding-top: 15px;
  line-height: normal;
}

.box p {
  margin: 0;
  text-align: center;
  color: #292929;
  margin-top: 10px;
  font-weight: 600;
  position: relative;
}

.box p::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 50%;
  background-color: #f37443;
  transition: width 0.3s ease, left 0.3s ease;
}

.box p:hover::after {
  color: #f37443;
  /* border-bottom: 1px solid rgb(253, 186, 17); */
  width: 100%;
  left: 0;
  /* width: 100px; */
}

/*  book online puja css start  */

.bookpuja {
  background: rgb(54, 53, 53);
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 30px 0px;
}

.online_puja {
  padding-top: 130px;
}

.online_puja h3 {
  font-size: 30px;
  font-weight: 600;
  color: black;
  margin-bottom: 0px;
}

.online_puja p {
  width: 85%;
  text-align: justify;
  color: grey;
  margin: 20px 0px 40px;
  line-height: 30px;
}

.updown-animation {
  animation-name: infiniteupdown;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 0.3s;
}

@keyframes infiniteupdown {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

/*  contact us css start  */
.contact_us {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 80px;
  background: rgb(233, 231, 231);
}

/* WhatsApp Icon */
/* .whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: white;
  z-index: 1000;
  transition: background-color 0.3s ease;
  font-size: 24px; 
} */

/* .whatsapp-icon:hover {
  background-color: #128c7e;
} */

.mantra {
  display: flex;
  justify-content: space-between;
}

.telegram-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.telegram-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #3fc250;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(62, 193, 79, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(62, 193, 79, 0);
  }
}

.telegram-icon svg {
  fill: #fff;
  width: 40px;
  height: 40px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%) scale(0.8);
    opacity: 0;
  }
  50% {
    transform: translateX(0) scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%) scale(0.8); /* Start off-screen and slightly scaled down */
    opacity: 0;
  }
  50% {
    transform: translateX(0) scale(1.05); /* Move to position and slightly scaled up */
    opacity: 0.7;
  }
  100% {
    transform: translateX(0) scale(1); /* End at original position and scale */
    opacity: 1;
  }
}

.mantra {
  display: flex;
  text-align: center;
  justify-content: space-between;
  position: relative; /* Ensure child elements are positioned correctly */
  padding: 20px;
  font-size: 20px;
}

.first_para {
  width: 200px;
  margin-top: -360px;
  font-weight: 600;
  line-height: 35px;
  /* font-family: "Poppins", sans-serif; */
  position: relative;
  margin-left: -1100px;
  animation: slideInFromLeft 2s ease-out forwards; /* Apply the left-slide animation */
}
.second_para {
  width: 200px;
  margin-top: -360px;
  font-weight: 600;
  line-height: 35px;
  /* font-family: "Poppins", sans-serif; */
  position: relative;
  margin-right: 70px;
  animation: slideInFromRight 2s ease-out forwards; /* Apply the right-slide animation */
}

.mandir-bell-a {
  height: 150px;
  width: 75px;
  margin-top: -600px;
  margin-left: 100px;
  animation: bounce 2s ease-in-out infinite; /* Apply swing animation */
}

.mandir-bell-b {
  height: 150px;
  width: 75px;
  margin-top: -600px;
  margin-right: -1100px;
  animation: bounce 2s ease-in-out infinite; /* Apply bounce animation */
}

.bell {
  height: 190px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.banner {
  margin-bottom: 20px;
}

.bg-banner {
  width: 100%;
  /* height: 580px; */
  border-radius: 20px;
}

.faq-section {
  padding: 60px 0;
  /* background-color: #f9f9f9; */
}

.faq-section .container {
  /* max-width: 900px; */
  margin: auto;
}

.faq-section h1 {
  text-align: center;
  font-size: 2.2rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
}

.faq-section p {
  text-align: center;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #737373;
}

.faq-item {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  background-color: #fff;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-question h5 {
  font-weight: 600;
  line-height: 30px;
}

.faq-question:hover {
  background-color: #f0f0f0;
}

.faq-answer {
  padding: 15px 20px;
  font-size: 1rem;
  color: gray;
  font-weight: 500;
  border-top: 1px solid #ddd;
}

.arrow {
  font-size: 1.2rem;
  transition: transform 0.3s;
}

.arrow.up {
  transform: rotate(180deg);
}

@media (max-width: 480px) {
  .faq-section h1 {
    font-size: 1.8rem;
  }
}

/* media screens */

@media only screen and (max-width: 1224px) {
  .first_para {
    margin-left: -1000px;
  }
  .title {
    padding-top: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .about-img {
    height: 340px;
    width: 90%;
    margin-left: 30px;
  }
  .title {
    margin-right: 20px;
    padding-top: 0px;
    padding-left: 10px;
  }
  .title p {
    padding-top: 5px;
    line-height: 28px;
    font-size: 16px;
  }
  .about-btn {
    padding: 12px 14px !important;
    font-size: 15px !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .bg-image {
    height: 350px;
  }
  .about-img {
    height: 315px;
    margin-left: 20px;
  }
  .title {
    margin-right: 10px;
    padding-top: 0px;
    padding-left: 0px;
  }
  .title p {
    line-height: 26px;
    font-size: 14px;
  }
  .title h2 {
    line-height: 20px;
    font-size: 30px;
    text-align: center;
  }
  .about-btn {
    padding: 10px 30px !important;
    font-size: 14px !important;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .bg-image {
    background-image: url("../Components/Assets/Hero baner mobail.webp");
    height: 250px;
  }
  .about-img {
    height: 265px;
    width: 80%;
    margin-left: 60px;
  }
  .title {
    margin-right: 40px;
    padding-top: 0px;
    padding-left: 40px;
  }
  .title p {
    line-height: 30px;
    font-size: 15px;
  }
  .about-btn {
    margin-top: 15px;
    margin-left: 80px;
  }
}

@media (max-width: 480px) {
  .bg-image {
    height: 500px;
  }
  .fas-fa-bella {
    margin-top: -445px;
    display: none;
    margin-left: 100px;
  }

  .fas-fa-bellb {
    margin-top: -445px;
    margin-right: -1545px;
    display: none;
  }
  .first_para {
    display: none;
  }

  .second_para {
    display: none;
  }
  .about {
    margin-top: 40px;
  }

  .about-img {
    height: 350px;
    width: 100%;
    margin-left: -5px;
  }
  .title {
    padding-left: 20px;
    padding-right: 10px;
  }

  .title h1 {
    font-size: 30px;
  }
  .title h5 {
    margin-top: 5px;
  }
  .title p {
    margin-right: -35px;
    margin-left: -15px;
    font-size: 16px;
    text-align: justify;
  }
  .table-box .box {
    width: 160px;
  }
  .services-title h1 {
    font-size: 35px;
  }
  .customer-header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 344px) {
  .table-box .box {
    width: 150px;
  }
}

/* @media only screen and (max-width: 350px) {
  .about {
    padding-bottom: 50px;
  }
  .about-img {
    height: 200px;
  }
  .title h5 {
    font-size: 18px;
  }
  .title p {
    font-size: 14px;
    line-height: 28px;
  }
  .title {
    padding-right: 0px;
  }
  .table-box .box {
    width: 150px;
}
} */
