.cart-page {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.main-cart-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cart-items1 {
  flex: 1;
  margin-right: 20px;
}

.cart-item1 {
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  align-items: center;
}

.cart-item:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.cart-item1 img {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.cart-item-details1 {
  flex-grow: 1;
}

.cart-item-details p {
  margin-top: 10px;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-top: 10px;
}

.quantity-control button {
  margin: 5px 10px;
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 2px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.quantity-control button:hover {
  background-color: #ff3300;
}

.remove-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.order-summary {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.order-summary:hover {
  transform: scale(1.02);
}

.order-summary h2 {
  margin: 0 0 10px;
  color: #000;
  font-weight: 600;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.summary-item1 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.summary-itemm {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  background: #ff6600;
  padding: 5px;
  color: #fff;
  font-size: 14px;
}

.summary-item:nth-child(1) {
  background-color: #d1ecf1;
  padding: 5px;
  border-radius: 3px;
}

.total {
  font-weight: bold;
  margin-top: 15px;
}

.checkout-button {
  background-color: #ff6600;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-button:hover {
  background-color: #ff3300;
}

.continue-shopping {
  display: inline-block;
  background-color: #ff6600;
  color: white;
  padding: 10px 20px;
  margin-right: 525px;
  margin-left: 525px;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.continue-shopping1 {
  display: inline-block;
  background-color: #ff6600;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.continue-shopping:hover {
  background-color: #ff3300;
}

.empty-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.empty-icon {
  font-size: 100px;
  color: black;
}

.empty-text {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.empty-subtext {
  color: gray;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .cart-page {
    padding: 10px;
  }

  .cart-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .cart-items {
    width: 100%;
    margin-right: 0;
  }

  .cart-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    flex-wrap: wrap;
  }

  .cart-item img {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }

  .cart-item-details {
    flex-grow: 1;
    text-align: left;
  }

  .quantity-control {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }

  .quantity-control button {
    margin: 0 5px;
  }

  .remove-button {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .order-summary {
    width: 90%;
    margin-top: 20px;
    text-align: center;
  }

  .checkout-button,
  .continue-shopping1 {
    width: 100%;
    text-align: center;
  }
}
