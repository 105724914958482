.problemDetails {
  padding-top: 40px;
  padding-bottom: 40px;
}

.problemImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.problem_samagri {
  margin-top: 15px;
}

.samagri_title {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.problem_samagri ul li {
  font-size: 15px;
  font-weight: 500;
  color: #2e2e2e;
}

.problem_name {
  width: 100%;
  margin-top: 15px;
  font-weight: 600;
  font-size: 28px;
  text-align: start;
  line-height: unset;
  color: #1a2b3c;
}

.problem_desc {
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
  color: #1a2b3c;
}

.temple-icon {
  width: 40px;
  height: 40px;
  fill: #f8863d;
}

.problem_temple_name {
  margin-left: -10px;
  font-size: 15px;
  font-weight: 600;
  color: #ff5757;
  margin-top: 5px;
  display: flex;
}

.problem_temple_name span {
  margin-top: 14px;
}

.problem_joinnow_btn {
  /* width: 40%; */
  outline: none;
  border: none;
  font-weight: 600;
  border-radius: 10px;
  /* padding: 10px; */
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
  background: linear-gradient(
      120deg,
      #0000 33%,
      hsla(0, 0%, 100%, 0.5) 50%,
      #0000 66%
    ),
    linear-gradient(90deg, #ff9800, #ff5757);
  background-size: 250% 250%, 100% 100%;
  cursor: pointer;
  animation: pujaDetails 2s infinite;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

@keyframes pujaDetails {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.problem_feedbacknow_btn {
  /* width: 40%; */
  outline: none;
  border: none;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  color: #fff;
  background: linear-gradient(
      120deg,
      #0000 33%,
      hsla(0, 0%, 100%, 0.5) 50%,
      #0000 66%
    ),
    linear-gradient(90deg, #91ff48, #08b116);
  background-size: 250% 250%, 100% 100%;
  cursor: pointer;
  animation: pujaDetails 2s infinite;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.bookNowButton:hover {
  background-color: #e5533b;
}

.problem_content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.booking_text {
  color: #2e2e2e;
  font-weight: 600;
  font-size: 15px;
}

.problem_box {
  flex: 1;
  max-width: 22%;
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.problem_box:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.image-container {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.box-img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.box-title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 5px;
  color: #1a2b3c;
}

.box-price {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-bottom: 15px;
}

/* PujaParticipation.css */

.puja-participation {
  margin-top: 40px;
  padding: 40px 20px;
  background-color: #fff;
  border: 1px solid #d9cece;
  /* text-align: center; */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.puja-participation h2 {
  color: #2e2e2e;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.puja-participation .highlight {
  color: #ff8f0b;
}

.puja-participation .subtext {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #515969;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 20px; */
}

.step {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-template-columns: 0.3fr 1.7fr;
  /* background: linear-gradient(135deg, #ffb347, #ff7f00); */
  border-radius: 12px;
  padding: 20px;
  color: rgb(12, 12, 12);
  text-align: left;
  transition: transform 0.3s ease-in-out;
}

.step-icon {
  /* font-size: 32px; */
  margin-bottom: 10px;
  padding: 10px;
  color: #fff;
  background: linear-gradient(90deg, #ff9800, #ff5757);
  height: 50px;
  width: 50px;
  border-radius: 15px;
  border: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step h3 {
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}

.step p {
  color: #515969;
  font-size: 15px;
}

@media (max-width: 768px) {
  .steps-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .steps-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .problem_box {
    max-width: 45%;
  }
}

@media screen and (max-width: 575.98px) {
  .problem_box {
    max-width: 100%;
  }
}

.poojaDetailsContainer {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #d9cece;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 40px auto;
}

.sectionTitle,
.sectionTitle1 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.questionAnswerContainer {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.questionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  border-bottom: 2px solid #ddd;
  transition: background-color 0.3s;
}

.questionContainer:hover {
  background-color: #e1e1e1;
}

.question {
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.arrow {
  font-size: 20px;
  color: #333;
  transition: transform 0.3s ease;
}

.answerContainer {
  padding: 15px;
  background-color: #fff;
  font-size: 16px;
  color: #555;
  border-top: 1px solid #ddd;
}

.answer {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .poojaDetailsContainer {
    padding: 20px;
  }

  .sectionTitle,
  .sectionTitle1 {
    font-size: 22px;
  }

  .question {
    font-size: 16px;
  }

  .arrow {
    font-size: 18px;
  }

  .answer {
    font-size: 14px;
  }
}

.aboutPoojaContainer {
  max-width: 100%;
  margin: 40px auto;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.aboutPoojaBox {
  padding: 20px;
  border: 1px solid #d9cece;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.sectionTitle1 {
  font-size: 16px;
  font-weight: 600;
  color: #1a2b3c;
  text-align: start;
  margin-bottom: 5px;
}

.aboutPoojaText p {
  font-size: 15px;
  color: #515969;
  line-height: 1.8;
  margin-bottom: 10px;
}

.showMoreText {
  display: inline-block;
  margin-top: 10px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.showMoreText:hover {
  color: #cd5702;
}

.separatorLine {
  width: 70px;
  height: 5px;
  background: linear-gradient(90deg, #ff9800, #ff5757);
  border-radius: 5px;
  margin: 6px 0 15px;
}

@media screen and (max-width: 768px) {
  .aboutPoojaContainer {
    padding: 15px;
  }

  .sectionTitle1 {
    font-size: 20px;
  }

  .aboutPoojaText p {
    font-size: 14px;
  }
}

@media screen and (max-width: 575.98px) {
  .problem_content {
    flex-direction: column;
    gap: 20px;
  }
  .problem_box {
    max-width: 100%;
  }
  .image-container {
    height: 150px;
  }
  .box-title {
    font-size: 16px;
  }
  .box-price {
    font-size: 14px;
  }
  .problem_feedbacknow_btn {
    margin-left: 0px;
  }
}
