/* blogpost.css */
.blogpost-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.blogpost {
  border-radius: 10px;
  padding: 30px;
  max-width: 1200px;
  width: 100%;
}

.blogpost-title {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.blogpost-featured-image img {
  width: 100%;
  border-radius: 10px;
  margin: 10px 0;
  object-fit: cover;
}

.blogpost-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.8;
}

.blogpost-content {
  margin-bottom: 20px;
}

.blogpost-paragraph {
  font-size: 1rem;
  color: #444;
  margin-bottom: 10px;
  line-height: 1.6;
}

.loading,
.error_blog {
  text-align: center;
  font-size: 1.5rem;
  color: #999;
}

@media (max-width: 575.98px) {
  .blogpost {
    padding: 15px;
  }

  .blogpost-title {
    font-size: 2rem;
  }

  .blogpost-description {
    font-size: 1rem;
  }

  .blogpost-paragraph {
    font-size: 0.9rem;
  }
}
