.feedback-form-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
}

.feedback-heading {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  animation: slideIn 0.5s ease-out;
}

.feedback-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 16px;
  color: #444;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(0, 204, 102, 0.5);
}

.form-group textarea {
  resize: vertical;
}

.rating-stars {
  display: flex;
  justify-content: start;
  gap: 10px;
}

.star {
  font-size: 30px;
  color: #ddd;
  cursor: pointer;
  transition: color 0.3s;
}

.star.filled {
  color: #ffcc00;
}

.submit-btn {
  padding: 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #45a049;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
