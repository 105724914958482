/* Container and general styles */
.container {
  padding: 0 15px;
}

.error {
  color: red;
  font-size: 0.9rem;
  /* margin-top: 0.5rem; */
}

.checkout {
  max-width: 800px;
  margin: 0 auto;
}

.checkout h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 2.5rem;
  color: #333;
}

.checkout-form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group .form-control {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
}

.form-group .form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.primary_btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #f86200;
  cursor: pointer;
  text-decoration: none;
}

.primary_btn:hover {
  background-color: #e65700;
}

.primary_btn:focus,
.primary_btn:active {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.payment-method {
  padding: 15px;
  text-align: left; 
}

.payment-method h4 {
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #333;
  text-transform: uppercase;
  /* font-weight: bold; */
}

.payment-method label {
  display: inline-flex; 
  align-items: center;
  margin: 0 10px; 
  font-size: 1rem;
  color: #495057;
  cursor: pointer;
}

.payment-method input[type="radio"] {
  margin-right: 10px;
  accent-color: #f86200; 
}

.payment-method label:hover {
  color: #f86200;
}
