/* Container for the form to center it and add padding */
.buynow-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.buynow-title {
  font-size: 30px;
  font-weight: 600;
  color: #333;
  /* margin-bottom: 20px; */
  text-align: center;
  margin-top: 20px;
}

.buynow-form {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
}

.buynow-price-box {
  background: rgb(248, 117, 69);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.current-price-product {
  font-size: 16px;
}

.old-price {
  text-decoration: line-through;
  margin-right: 8px;
}

.current-price {
  font-weight: bold;
}

.form-group-box {
  margin-bottom: 20px;
}

.form-group-box label {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
}

.form-group-box span {
  color: red;
}

.input-product {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.buynow-btn {
  background-color: rgb(248, 117, 69);
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.buynow-btn:hover {
  background-color: rgb(220, 100, 60);
}
