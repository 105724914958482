.sub_header_ecommerce {
  background-image: url("../Components/Assets/ecommercebanner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
}

.sub_header_ecommerce .subheader_inner_ecommerce {
  padding: 200px 0px 180px;
}

.subheader_inner_ecommerce {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.subheader_text_ecommerce h1 {
  font-size: 70px;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  text-align: left;
}

.sub_header_ecommerce .breadcrumb {
  padding: 20px 28px;
  margin: 0;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.sub_header_ecommerce .breadcrumb li {
  margin: 0;
  color: #f1f1f1;
}

.sub_header_ecommerce .breadcrumb li a {
  position: relative;
  list-style: none;
  text-decoration: none;
  color: #161616;
  font-weight: 700;
  font-size: 14px;
}

.btn-link {
  text-transform: uppercase;
  cursor: pointer;
}

.sub_header_ecommerce .breadcrumb .breadcrumb-item.active {
  color: #fc6805;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sub_header_ecommerce .breadcrumb .breadcrumb-item {
  padding-left: 15px;
}

.section-title {
  color: #292929;
  font-size: 36px;
  text-align: center;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.tp-box1 {
  border: 1px solid #dadada;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 0 1 calc(33.33% - 20px);
  margin: 10px;
  box-sizing: border-box;
}

.tp-box1:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.tp-box1 img {
  max-width: 100% !important;
  width: 100%;
  height: 350px;
  transition: transform 0.3s ease;
}

.tp-box1:hover img {
  transform: scale(1.05);
}

.tp-box-content h6 {
  color: #212121;
  font-size: 22px;
  margin-top: 15px !important;
  margin-bottom: 0.5rem;
}

.tp-box-content .price-booknow .price-group .original-price {
  color: #212121;
  font-size: 14px;
  margin-bottom: 5px;
  text-decoration: line-through;
}

.tp-box-content .price-booknow .price-group .current-price strong {
  font-size: 18px;
  color: rgb(248, 117, 69);
}

.tp-box-content .price-booknow .booknow-btn {
  background-color: rgb(248, 117, 69);
  color: #fff;
  border-radius: 150px;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 15px;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0;
  white-space: nowrap;
  border: none;
}

.ecommerce_card {
  padding: 20px;
}

.filterbox button {
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.filterbox button.active {
  background-color: rgb(248, 117, 69);
  color: #fff;
  border: none;
}

.filterbox input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
}

.filter-options-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  z-index: 10;
  width: 200px;
}

.filter-options-dropdown ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.filter-options-dropdown li {
  padding: 5px 10px;
  cursor: pointer;
}

.filter-options-dropdown li:hover {
  background: #f0f0f0;
}

.product-detail-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 20;
}

.product-detail-modal h2 {
  margin: 0 0 10px;
}

.product-detail-modal img {
  max-width: 100%;
  margin-bottom: 10px;
}

.product-detail-modal button {
  background-color: #f11769;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.active-filter {
  background-color: rgb(248, 117, 69);
  color: #fff;
  border: none;
}

.filter-options-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #dadada;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 300px;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
}

.filter-options-popup h5 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #292929;
  text-align: center;
}

.filter-options-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filter-options-popup li {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
  border-bottom: 1px solid #e0e0e0;
}

.filter-options-popup li:hover {
  background-color: #f0f0f0;
}

.filter-options-popup li:last-child {
  border-bottom: none;
}

.filter-options-popup button {
  margin-top: 10px;
  background-color: rgb(248, 117, 69);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s;
}

.filter-options-popup button:hover {
  background-color: rgb(220, 100, 50);
}

@media (max-width: 768px) {
  .product-container {
    flex-direction: column;
  }

  .tp-box1 {
    flex: 0 1 100%;
    margin: 10px 0px;
  }

  .filterbox input {
    width: 100%;
    margin-top: 10px;
    margin-left: 5px;
  }

  .filter-options-popup {
    width: 90%;
    max-width: 300px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .product-container {
    flex-wrap: wrap;
  }

  .tp-box1 {
    flex: 0 1 calc(50% - 20px);
  }
}

@media (min-width: 1025px) {
  .tp-box1 {
    flex: 0 1 calc(33.33% - 20px);
  }
}

@media only screen and (max-width: 480px) {
  .sub_header_ecommerce .subheader_inner_ecommerce {
    padding: 75px 0px 60px !important;
  }
  .subheader_text_ecommerce h1 {
    font-size: 20px !important;
  }
  .sub_header_ecommerce .breadcrumb {
    padding: 10px 5px !important;
    margin: 0;
    bottom: -22px !important;
    left: 50%;
  }
  .sub_header_ecommerce .breadcrumb .breadcrumb-item {
    padding-left: 10px;
  }
  .sub_header_ecommerce .breadcrumb li a {
    font-size: 12px !important;
  }
  .sub_header_ecommerce .breadcrumb .breadcrumb-item.active {
    font-size: 12px !important;
  }
  .filterbox button {
    margin-top: 10px;
    margin-left: 5px;
  }
}
