.sub_header3 {
  background-image: url("../Components/Assets/ContactUsbanner.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
}

.sub_header3 .subheader_inner_enquiry {
  padding: 180px 0px 150px;
}

.subheader_inner_enquiry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subheader_text_enquiry h1 {
  font-size: 60px;
  margin-bottom: 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.sub_header3 .breadcrumb {
  padding: 20px 28px;
  margin: 0;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 20px 0px rgb(53 82 99 / 9%);
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.sub_header3 .breadcrumb li {
  margin: 0;
  color: #f1f1f1;
}

.sub_header3 .breadcrumb li a {
  position: relative;
  list-style: none;
  text-decoration: none;
  color: #161616;
  font-weight: 700;
  font-size: 14px;
}

.btn-link {
  text-transform: uppercase;
  cursor: pointer;
}

.sub_header3 .breadcrumb .breadcrumb-item.active {
  color: #fc6805;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sub_header3 .breadcrumb .breadcrumb-item {
  padding-left: 15px;
}

.contact-title {
  color: #292929;
  font-size: 36px;
  text-align: center;
}

.form-group textarea.input {
  height: 80px;
}

.address strong {
  margin-right: 8px;
}

.address p {
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.submit-button {
  padding: 8px 14px !important;
  font-size: 16px !important;
  font-weight: 600;
  background-color: #f37443;
  color: #fff;
  border-radius: 5px;
}

.submit-button:hover {
  background: orangered;
  color: white;
}

@media only screen and (max-width: 480px) {
  .sub_header3 .subheader_inner_enquiry {
    padding: 45px 0px 60px;
  }
  .subheader_text_enquiry h1 {
    font-size: 40px;
  }
  .sub_header3 .breadcrumb {
    padding: 10px 5px;
    margin: 0;
    bottom: -22px;
    left: 50%;
  }
  .sub_header3 .breadcrumb .breadcrumb-item {
    padding-left: 10px;
  }
  .sub_header3 .breadcrumb li a {
    font-size: 12px;
  }
  .sub_header3 .breadcrumb .breadcrumb-item.active {
    font-size: 12px;
  }
  .address {
    margin-left: -40px;
  }
}
